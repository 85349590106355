import React from 'react'
function Thankyou() {

    
  return (
    <div>
thankyou
    </div>
  )
}

export default Thankyou