import './App.css';
import Thankyou from './thankyou';
import Regiser from './Regiser';
import PagNotFound from './PagNotFound';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
      <BrowserRouter>
        <Routes>
        <Route path='/'element={<Regiser/>}/>
        <Route path='/thankyou' element={<Thankyou/>}/>
        <Route path="*" element={<PagNotFound/>}/>
        </Routes>
      </BrowserRouter>
  );
}
export default App;
