import React from "react";
import { useState } from "react";
import "./register.css";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import 'dayjs/locale/en-gb';
import Box from '@mui/material/Box';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const currentDate = new Date();
const eighteenYearsAgo = new Date(currentDate);
eighteenYearsAgo.setFullYear(currentDate.getFullYear() - 13);
const endOfQ12022 = dayjs(eighteenYearsAgo);
function Regiser() {
  const BASE_URL = "https://genz-api.akbgroup.in";
  // const BASE_URL = 'http://localhost:5003'
  const navigate = useNavigate();
  const [full_name, setUsername] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setMobum] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setCpass] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [hideSendOtpbtn, setHideSendOtpbtn] = useState(true)
  const [displayThankyou, setDisplayThankYou] = useState(false);
  const [error, setError] = useState(false);
  // const [error, seterror] = useState(false)
  const [uname, setUnameerr] = useState(false);
  const [errage, setAgeErr] = useState(false);
  const [errMob, setMoberr] = useState("");
  const [emailerr, setEmailerr] = useState(false);
  const [otperr, setOtpErr] = useState("");
  const [EnableResendotp, setEnableResendotp] = useState(false);

  

  const handleRegisteruser = async (e) => {
    e.preventDefault();
    if (phone.length < 10 && phone.split('')[0] =='6' && phone.split('')[0]=='7' && phone.split('')[0]=='9' && phone.split('')[0]=='8') {
      
      setMoberr("Enter valid mobile number");
    }
    if (full_name.length <= 3) {
      setUnameerr(true);
    }
    if (!dob) {
      setAgeErr(true);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailerr(true);
    }
    if (otp.length != 6) {
      setOtpErr("Please enter valid OTP");
    } else {
    }

    console.log(full_name, dob, phone, email, otp);
    if (
      full_name.length > 2 &&
      phone.length >= 10 &&
      emailRegex.test(email) &&
      otp.length == 6 &&
      dob
    ) {
      setUnameerr(false);
      setAgeErr(false);
      setEmailerr(false);
      setOtpErr('')

      console.log(full_name, dob, phone, email, otp);
      const str = dob.$d;
      const date = new Date(str);
      date.setDate(date.getDate() + 1); // Add one day
      const isoDate = date.toISOString();

      const formData = new FormData();
      formData.append("full_name", full_name);
      formData.append("dob", isoDate);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("otp", otp);

      const resp = await fetch(`${BASE_URL}/register`, {
        method: "POST",
        body: new URLSearchParams(formData).toString(),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const data = await resp.json();

      if (data.status_code == 200) {
        setDisplayThankYou(true);
        // navigate("/thankyou")
        // const successStyles = {
        //   display: 'block', // Set your desired display property for success
        //   // Add other styles as needed
        // };
      } else if (data.status_code == 400) {
        setOtpErr("Please Enter valid OTP");
      } else {
        // navigate("/")
        // seterror(true)
      }
    } else {
    }
  };
  async function sendOtp(e) {
    // 
    // console.log(dob.$d, "ppppppppppp");
    if ((phone && phone.length >= 10) && (phone.split('')[0] == '6' || phone.split('')[0] == '7' || phone.split('')[0] == '9' || phone.split('')[0] == '8')) {
      e.preventDefault();
      setHideSendOtpbtn(false)
      setMoberr("");
      setEnableResendotp(false);
      const formData = new FormData();
      formData.append("phone", phone);

      try {
        
        const resp = await fetch(`${BASE_URL}/otp-verify`, {
          method: "POST",
          body: new URLSearchParams(formData).toString(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const data = await resp.json();
        if (data.status_code == 200) {
          setIsOtpSent(true);
        } else if (data.status_code == 204) {
          setIsOtpSent(false)
          return setMoberr("Phone number already exists");
        } else {
          setIsOtpSent(false)
          console.error("API call failed");
        }
        setTimeout(() => {
          setEnableResendotp(true);
        }, 10000);
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      setMoberr("Please enter a valid mobile number");
    }
  }
  const handleFirstInput = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value.length == 10 && setMoberr('')
    setIsOtpSent(false)
    setHideSendOtpbtn(true)
    value = value.slice(0, 10); // Limit to 10 digits
    setMobum(value);
  };

  const handleSecondInput = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value.length == 6 && setOtpErr('')
    value = value.slice(0, 6); // Limit to 6 digits
    setCpass(value);
  };

  const handleDOB = (event) => {
    event && setAgeErr('')
     setDob(event)
  }

  const handleFullname = (event) => { 
    event.target.value.length >= 3 && setUnameerr(false);
    setUsername(event.target.value)
  }
  const handleEmail = (e) => {
    console.log(e.target.value);
    const inputValue = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setEmail(inputValue);
    // setTimeout(() => {
    emailRegex.test(inputValue) && setEmailerr(false) 
    // }, 5000);

    // e.target.value.length >= 5 && e.target.value.split('').includes("@") && e.target.value.split('').includes(".") && setEmailerr(false);
    // setEmail(e.target.value)
  }
  
  return (
    <>
      <div className="bg-section"></div>

      <div className="inside-img">
        <div className="img-section">
          <img src={require("./images/Group_2.png")} alt="" />
        </div>
      </div>

      <div className="section-1">
        <div className="section-2">
          <h1>Exclusive Haagen-Dazs Freebies!</h1>
          <p>
            Unlock a world of indulgence! Sign up now to enjoy exclusive
            Haagen-Dazs freebies, available only at select franchise-operated
            stores.
          </p>
        </div>

        {!displayThankyou ? (
          <div className="form-section">
            <div className="form">
              <div className="input-divs">
                <label className="form-label" htmlFor="name">
                  Full Name *
                </label>
                <br />
                <input
                  className="tbox"
                  type="text"
                  name=""
                  id="name"
                  onChange={handleFullname}
                  value={full_name}
                  placeholder="Name"
                  required
                />
                {uname ? (
                  <div style={{ color: "red", marginTop: 8, fontSize: 14 }}>
                    Please enter your full name
                  </div>
                ) : null}
              </div>

              <div className="input-divs">
                <label className="form-label" htmlFor="email">
                  Email *
                </label>
                <br />
                <input
                  className="tbox"
                  type="email"
                  name=""
                  id="email"
                  value={email}
                  onChange={handleEmail}
                  placeholder="Email"
                  required
                />
                {emailerr ? (
                  <div style={{ color: "red", marginTop: 8, fontSize: 14 }}>
                    Please enter a valid email address
                  </div>
                ) : null}
              </div>

              <div className="input-divs">
                <label className="form-label dob-class" htmlFor="dob">
                  Date Of Birth *
                </label>
                <br />

                {/* {focused ? (
        <input
          className='tbox'
          type="date"
          id="dob"
          onChange={(e) => setDob(e.target.value)}
          onBlur={() => setFocused(false)}
          value={dob}
          required
        />
      ) : (
        <input
          className='tbox'
          type="text"
          id="dob"
          onFocus={() => setFocused(true)}
          placeholder="Enter Date of Birth"
          value={dob}
          required
        />
      )} */}
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
        <DemoContainer components={['MobileDatePicker']}>
        <MobileDatePicker 
          views={['year', 'month', 'day']}  
          maxDate={endOfQ12022} 
          value={dob}
          placeholder="Enter Date of Birth"
          onChange={handleDOB}
          />
      </DemoContainer>
    </LocalizationProvider>
                {/* <input   className='tbox'  type={focused ? "date" : "text"}  onFocus={() => setFocused(true)}
        onBlur={() => setFocused(true)}   id="dob" onChange={(e) => setDob(e.target.value)} value={dob} placeholder="Enter Date of Birth" required /> */}

                {errage ? (
                  <div style={{ color: "red", marginTop: 8, fontSize: 14 }}>
                    Please enter your date of birth

                  </div>
                ) : null}
              </div>

              <div className="input-divs">
                <label className="form-label" htmlFor="phone">
                  Mobile *
                </label>
                <br />
                <input
                  className="tbox"
                  type="text"
                  name=""
                  id="phone"
                  onChange={handleFirstInput}
                  value={phone}
                  placeholder="Enter 10 digit mobile number"
                  required
                />
                {errMob ? (
                  <div style={{ color: "red", marginTop: 8, fontSize: 14 }}>
                    {" "}
                    {errMob}{" "}
                  </div>
                ) : null}
                {hideSendOtpbtn ? 
                  <button onClick={sendOtp} className="submit-button">
                    Send OTP
                  </button> : ''
                }
              </div>

              {isOtpSent && (
                <div className="input-divs">
                  <label className="form-label" htmlFor="email">
                    OTP
                  </label>
                  <br />
                  <input
                    className="tbox otp-input"
                    type="text"
                    name=""
                    id="email"
                    value={otp}
                    onChange={handleSecondInput}
                    placeholder="Enter 6 digit OTP"
                    required
                  />
                  {otperr ? (
                    <div style={{ color: "red", marginTop: 0, fontSize: 14 ,marginBottom:5}}>
                      {otperr}
                    </div>
                  ) : null}
                  {EnableResendotp ? (
                    <span className="resend-span"
                      onClick={sendOtp}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bolder",
                        color: "#650a30",
                        fontSize: "15px",
                        marginTop: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Resend OTP
                    </span>
                  ) : (
                    <span
                      onClick={sendOtp}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bolder",
                        color: "rgb(4 155 38",
                        fontSize: "15px",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      OTP Sent Successfully
                    </span>
                  )}{" "}
                  <button
                    className="submit-button"
                    onClick={handleRegisteruser}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="thank-contanier">
            <div className="img-sec">
              <div className="imgdiv">
                <img
                  style={{ width: "30px" }}
                  src={require("../src/images/Artwork3.svg").default}
                  alt=""
                />
              </div>
              <p className="respnse-text">Response has been submitted</p>
            </div>

            <p className="respnse-text-1">Last Step: Leave a Google Review</p>
            <p className="respnse-text-2">
              Help others discover us by leaving a review! Click the button
              below to share your experience, with or without images.{" "}
            </p>

            <p className="respnse-text-3">
              Your feedback is greatly appreciated.
            </p>

            <div className="anchor-div">
              <a className="anchor-button" href="https://g.co/kgs/B5JZiAv">
                Write a review
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="section-5">
        <h1>Frequently asked questions</h1>

        <div>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  How do customers join the freebie offer?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Fill out the form on our landing page. Only customers who've
                  completed a purchase can qualify.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What freebies are offered?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Expect a variety of treats, from single-serve ice creams to
                  discounts. Availability varies by location.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Are all franchises participating?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Only select locations are part of this promotion. Check our
                  site for details.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How to know if eligible for freebies?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  On the day of billing, if you are given link to participate -
                  you are counted as eligible.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Can customers receive multiple freebies?
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Limited to one freebie per customer to ensure broad
                  participation.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Offer duration?
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  It's a limited-time offer, available while supplies last.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How is customer information used?
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Information is strictly for this promotion and improving
                  service, not shared without consent.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-4">
        <h5>© 2024 AKB Foods.
 All Rights Reserved.</h5>
      </div>
    </>
  );
}

export default Regiser;
